.header-color, .landing-grid {
    background: #9CECFB;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #0052D4, #65C7F7, #9CECFB);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #0052D4, #65C7F7, #9CECFB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    text-align: center;
}

.landing-container {
    min-height: 100vh; /* Ensure the container fills the viewport height */
    position: relative;
}

.background-container {
    background-size: cover;
    background-position: center;
    text-align: center;
    min-height: 100vh; /* Ensure the container fills the viewport height */
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, #0052D4, #65C7F7, #9CECFB);
    z-index: -1;
}

.landing-grid {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    min-height: 100vh; /* Ensure the container fills the viewport height */
}

@media (max-width: 1200px) {
    .project-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .project-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .project-grid {
        grid-template-columns: 1fr;
    }
}

.about-body {
    display: flex;
    align-items: flex-start; /* Align items to the top */
    justify-content: center;
    text-align: center;
    margin: auto;
    position: fixed;
    width: 100%;
    height: 100%;
    padding-top: 2em;
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
}

.about-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.about-body h2 {
    font-family: 'Anton', sans-serif;
    font-weight: bold;
    color: white;
}

.about-body p, .about-body a {
    padding: 2em;
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    color: white;
    grid-column: 2; /* Align the <p> tag to the middle column */
    max-width: calc(100vw / 3); /* Set the max width to one third of the screen */
    margin: 0 auto; /* Center the <p> tag horizontally within the column */
}

.contact-body {
    text-align: center;
    margin: auto;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-top: 2em;
}

.contact-body h2 {
    font-family: 'Anton', sans-serif;
    font-weight: bold;
}

.contact-grid {
    text-align: center;
    justify-content: center;
    width: 80%;
    height: 550px;
    background: white;
    margin-bottom: 20px;
}

.contact-grid h2 {
    font-family: 'Anton', sans-serif;
}

.contact-grid p {
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
}

.contact-grid hr {
    border-top: 5px dotted;
    width: 50%;
    margin: auto;
}

.contact-list {
    padding-left: 100px;
}

.avatar-img {
    height: 250px;
    width: 250px;
    border-radius: 50%;
}

.banner-text {
    opacity: 0.8;
    width: 75%;
    margin: auto;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity and color as needed */
    padding: 20px; /* Adjust the padding as needed */
}

.banner-text h1 {
    font-size: 66px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Add a text shadow for better visibility */
}

.banner-text hr {
    border-top: 20px solid #833fb2;
    width: 50%;
    margin: auto;
}

.banner-text p {
    color: white;
    font-size: 20px;
    padding: 1em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Add a text shadow for better visibility */
}

.social-links {
    display: flex;
    justify-content: space-between;
    width: 25%;
    margin: auto;
    background-size: cover;
    background-position: center;
    padding: 20px; /* Adjust the padding as needed */
    background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity and color as needed */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Add a text shadow for better visibility */
}

.linkedin-icon, .github-icon {
    color: white;
    font-size: 3em;
}

.footer {
    background-color: teal;
    color: white;
    padding: 10px;
    text-align: center;
}

/* Main container */
.landing-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh; /* Full height of the viewport */
    padding: 20px;
}

/* Avatar and Introduction styling */
.intro {
    margin-bottom: 30px;
}

.avatar {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

/* Resume Download Section */
.resume-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.resume-buttons a {
    text-decoration: none;
}

.resume-button {
    background-color:magenta;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.resume-button:hover {
    background-color: #45a049;
}

.resume-button:focus {
    outline: none;
}

.resume-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
